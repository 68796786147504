<template>
  <div ref="rank" v-lazyChart="{ fn: getClassNUmList }" class="container">
    <div class="operate">
      <div class="filter">
        <a-select
          v-model="type"
          style="width: 120px; margin-right: 8px"
          allow-clear
          placeholder="全部类型"
          @change="typeChange"
        >
          <a-select-option
            v-for="(item, index) in cateOptions"
            :key="index"
            :value="item.id"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
        <a-select
          v-model="eleccombId"
          style="width: 120px; margin-right: 8px"
          placeholder="全部组合"
          allow-clear
          @change="eleccombIdChange"
        >
          <a-select-option
            v-for="(item, index) in eleccomboption"
            :key="index"
            :value="item.id"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
        <a-select
          v-model="classNum"
          style="width: 150px; margin-right: 8px"
          :placeholder="
            [0, 1, 2, 3].includes(subjectId) ? '全部行政班级' : '全部教学班级'
          "
          allow-clear
          @change="classNumChange"
        >
          <a-select-option
            v-for="(item, index) in classNUmList"
            :key="index"
            :value="item.name"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
        <a-input
          v-model="input"
          placeholder="搜索学生姓名可定位学生"
          style="width: 220px"
          @input="inputChange"
        ></a-input>
      </div>
      <a-button type="primary" :loading="exportLoading" @click="exportExcel"
        >导出</a-button
      >
    </div>
    <a-table
      :key="tokenKey"
      :columns="columns"
      :row-key="
        (record, index) => {
          return index;
        }
      "
      :loading="loading"
      :data-source="tableData"
      bordered
      :scroll="{ x: 'max-content' }"
      :pagination="false"
    >
      <template slot="scoreOri">
        <div>原始分</div>
      </template>
      <template slot="score">
        <div>赋分</div>
      </template>
      <template slot="classRankOri">
        <div>班级排名</div>
        <div v-if="![1, 2, 3, 4, 8].includes(subjectId)">（原始分）</div>
      </template>
      <template slot="classRank">
        <div>班级排名</div>
        <div>（赋分）</div>
      </template>
      <template slot="schoolRankOri">
        <div>校排名</div>
        <div v-if="![1, 2, 3, 4, 8].includes(subjectId)">（原始分）</div>
      </template>
      <template slot="schoolRank">
        <div>校排名</div>
        <div>（赋分）</div>
      </template>
      <template slot="unionRankOri">
        <div>整体排名</div>
        <div v-if="![1, 2, 3, 4, 8].includes(subjectId)">（原始分）</div>
      </template>
      <template slot="unionRank">
        <div>整体排名</div>
        <div>（赋分）</div>
      </template>
      <template slot="actions" slot-scope="text, record">
        <a-button type="link" @click="studentDtail(record)">查看学情</a-button>
      </template>
      <template slot="weak" slot-scope="text, record">
        <div v-if="record.littleSubject && record.littleSubject.length">
          <span>{{ record.littleSubject.join(",") }}</span
          >稍薄弱
        </div>
        <div
          v-if="record.seriousSubject && record.seriousSubject.length"
          class="weak"
        >
          <span>{{ record.seriousSubject.join(",") }}</span
          >严重偏科
        </div>
      </template>
    </a-table>
    <div class="pagination">
      <a-pagination
        show-size-changer
        :default-current="pagination.current"
        :total="pagination.total"
        :page-size="pagination.pageSize"
        :page-size-options="['10', '20', '30', '40', '50']"
        :show-total="(total, range) => `共计${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="onShowPageChange"
      />
    </div>
  </div>
</template>

<script>
import {
  getstatclassoption,
  getstateleccateoption,
  getstateleccomboptionV2,
} from "@/core/api/academic/common";
import {
  studentlistpage,
  newExamExportstudentscore,
} from "@/core/api/newExamAcademic/student";
import {
  unionStudentlistpage,
  unionExportstudentscore,
} from "@/core/api/newExamAcademic/union/student";
export default {
  name: "",
  props: {
    subjectId: {
      default: "",
      type: [String, Number],
    },
    topData: {
      default: () => {},
      type: Object,
    },
  },
  data() {
    return {
      type: undefined,
      eleccomboption: [],
      eleccombId: undefined,
      classNUmList: [],
      classNum: undefined,
      exportLoading: false,
      cols: [
        {
          title: "考号",
          align: "center",
          dataIndex: "studentExamId",
          width: 150,
          fixed: true,
        },
        {
          title: "姓名",
          align: "center",
          dataIndex: "name",
          width: 100,
          fixed: true,
        },
        {
          title: "班级",
          align: "center",
          dataIndex: "classNum",
          width: 100,
          sorter: (a, b) => {
            if ([0, 1, 2, 3, 4, 8].includes(this.subjectId)) {
              return a.classNum - b.classNum;
            } else {
              return a.classNumTeach - b.classNumTeach;
            }
          },
          fixed: true,
          customRender: (text, record) => {
            if ([0, 1, 2, 3, 4, 8].includes(this.subjectId)) {
              return record.classNum;
            }
            return record.classNumTeach;
          },
        },
        {
          title: "选考组合",
          align: "center",
          dataIndex: "eleccomb",
          fixed: true,
          width: 100,
        },
        {
          title: "操作",
          align: "center",
          dataIndex: "action",
          scopedSlots: { customRender: "actions" },
          fixed: true,
          width: 100,
        },
        {
          align: "center",
          dataIndex: "scoreOri",
          slots: { title: "scoreOri" },
          sorter: (a, b) => a.scoreOri - b.scoreOri,
        },
        {
          align: "center",
          dataIndex: "score",
          slots: { title: "score" },
          sorter: (a, b) => a.score - b.score,
        },
        {
          align: "center",
          dataIndex: "classRank",
          slots: { title: "classRank" },
          sorter: (a, b) => a.classRank - b.classRank,
        },
        {
          align: "center",
          dataIndex: "schoolRank",
          slots: { title: "schoolRank" },
          sorter: (a, b) => a.schoolRank - b.schoolRank,
          defaultSortOrder: "ascend",
        },
        {
          title: "薄弱情况",
          align: "center",
          dataIndex: "weak",
          scopedSlots: { customRender: "weak" },
        },
      ],
      tableData: [],
      columns: [],
      input: undefined,
      tokenKey: Math.random(),
      cateOptions: [],
      loading: false,
      pagination: {
        current: 1,
        pageSize: 50,
        total: 0,
        pageSizeOptions: [10, 20, 50, 100, 200, 500, 1000],
      },
    };
  },
  created() {},
  methods: {
    async getCate() {
      let data = {
        id: this.$route.query.id,
        classNum: this.classNum,
        subjectId: this.subjectId == 0 ? null : this.subjectId,
        viewSchoolId: this.$route.query.schoolId,
      };
      if (!this.classNum) {
        this.$delete(data, "classNum");
      }
      if (!this.subjectId) {
        this.$delete(data, "subjectId");
      }
      const res = await getstateleccateoption(data);
      if (res.data.data.length) {
        this.cateOptions = res.data.data;
      }
    },
    async getGourpList() {
      const res = await getstateleccomboptionV2({
        id: this.$route.query.id,
        classNum: this.classNum,
        subjectId: this.subjectId,
        eleccateId: this.type || 0,
        viewSchoolId: this.$route.query.schoolId,
      });
      if (res.data.data.length) {
        this.eleccomboption = res.data.data;
      }
    },
    async exportExcel() {
      this.exportLoading = true;
      let res;
      if (this.$route.query.examType == 1) {
        res = await unionExportstudentscore({
          id: this.$route.query.id,
          subjectId: this.subjectId,
          viewSchoolId: this.$route.query.schoolId,
        });
      } else {
        res = await newExamExportstudentscore({
          id: this.$route.query.id,
          subjectId: this.subjectId,
        });
      }

      this.exportLoading = false;
      const { href } = this.$router.resolve({
        path: "/downFile",
        query: {
          url: res.data.data.url,
          name: res.data.data.name,
          type: 1,
        },
      });
      let timer = setTimeout(() => {
        window.open(href, "_blank");
        clearTimeout(timer);
      }, 0);
    },
    inputChange() {
      this.columns = [];
      this.pagination.current = 1;
      this.getData();
    },
    onShowSizeChange(current, size) {
      this.pagination.pageSize = size;
      this.getData();
    },
    onShowPageChange(page) {
      this.pagination.current = page;
      this.getData();
    },
    getClassType() {
      if ([0, 1, 2, 3, 4, 8].includes(this.subjectId)) {
        return "行政";
      } else {
        return "行政";
      }
    },
    eleccombIdChange() {
      this.columns = [];
      this.pagination.current = 1;
      this.getData();
    },
    classNumChange() {
      this.columns = [];
      this.pagination.current = 1;
      this.type = undefined;
      this.eleccombId = undefined;
      this.getCate();
      this.getData();
      this.getGourpList();
    },
    studentDtail(record) {
      const { href } = this.$router.resolve({
        path: "/academic/new-exam/student-report",
        query: {
          ...this.$route.query,
          studentName: record.name,
          studentId: record.studentId,
          subjectId: this.subjectId,
          classNum: record.classNum,
          level: this.$route.query.level,
        },
      });
      let timer = setTimeout(() => {
        window.open(href, "_blank");
        clearTimeout(timer);
      }, 0);
    },
    async getData() {
      this.tableData = [];
      this.columns = [];
      this.loading = true;
      let res;
      if (this.$route.query.examType == 1) {
        res = await unionStudentlistpage({
          statId: this.$route.query.id,
          subjectId: this.subjectId,
          classNum: this.classNum,
          size: this.pagination.pageSize,
          current: this.pagination.current,
          input: this.input,
          eleccombId: this.eleccombId || this.type,
          viewSchoolId: this.$route.query.schoolId,
        });
      } else {
        res = await studentlistpage({
          statId: this.$route.query.id,
          subjectId: this.subjectId,
          classNum: this.classNum,
          size: this.pagination.pageSize,
          current: this.pagination.current,
          input: this.input,
          eleccombId: this.eleccombId || this.type,
        });
      }

      this.loading = false;
      this.pagination.total = res.data.data.total;
      if (res.data.data.records.length) {
        if (this.subjectId == 0) {
          let mainSubjects = res.data.data.records[0].subjects.filter((item) =>
            [1, 2, 3, 4, 8].includes(item.subjectId)
          );
          let choosen = res.data.data.records[0].subjects.filter(
            (item) => ![1, 2, 3, 4, 8].includes(item.subjectId)
          );
          let cols = [...this.cols];
          if (this.$route.query.examType == 1) {
            if (this.topData.isSchoolDisplayReportStudentRank != 1) {
              cols = this.cols.filter(
                (item) =>
                  !["classRank", "schoolRank", "unionRank"].includes(
                    item.dataIndex
                  )
              );
            } else {
              cols.splice(9, 0, {
                align: "center",
                dataIndex: "unionRank",
                slots: { title: "unionRank" },
                sorter: (a, b) => a.unionRank - b.unionRank,
                defaultSortOrder: "descend",
              });
            }
          }
          this.columns = [
            ...cols,
            ...mainSubjects.map((item) => ({
              title: item.subjectName,
              align: "center",
              dataIndex: "subjects" + item.subjectId,
              sorter: (a, b) => {
                let aSubject = a.subjects.find(
                  (it) => it.subjectId === item.subjectId
                );
                let bSubject = b.subjects.find(
                  (it) => it.subjectId === item.subjectId
                );
                return aSubject.scoreOri - bSubject.scoreOri;
              },
              customRender: (text, record) => {
                let subject = record.subjects.find(
                  (it) => it.subjectId === item.subjectId
                );
                return subject.score;
              },
            })),
            ...choosen.flatMap((item, index) => {
              return {
                title: item.subjectName,
                dataIndex: item.subjectId,
                align: "center",
                children: [
                  {
                    title: "原始分",
                    align: "center",
                    dataIndex: "subjectsOri" + item.subjectId + index,
                    sorter: (a, b) => {
                      let aSubject = a.subjects.find(
                        (it) => it.subjectId === item.subjectId
                      );
                      let bSubject = b.subjects.find(
                        (it) => it.subjectId === item.subjectId
                      );
                      return aSubject.scoreOri - bSubject.scoreOri;
                    },
                    customRender: (text, record) => {
                      let subject = record.subjects.find(
                        (it) => it.subjectId === item.subjectId
                      );
                      return subject.scoreOri;
                    },
                  },
                  {
                    title: "赋分",
                    align: "center",
                    dataIndex: "subjects" + item.subjectId + index,
                    sorter: (a, b) => {
                      let aSubject = a.subjects.find(
                        (it) => it.subjectId === item.subjectId
                      );
                      let bSubject = b.subjects.find(
                        (it) => it.subjectId === item.subjectId
                      );
                      return aSubject.score - bSubject.score;
                    },
                    customRender: (text, record) => {
                      let subject = record.subjects.find(
                        (it) => it.subjectId === item.subjectId
                      );
                      return subject.score;
                    },
                  },
                ],
              };
            }),
          ];
        } else {
          let cols = [
            {
              title: "考号",
              align: "center",
              dataIndex: "studentExamId",
            },
            {
              title: "姓名",
              align: "center",
              dataIndex: "name",
            },
            {
              title: "班级",
              align: "center",
              dataIndex: "classNum",
            },
            {
              title: "选考组合",
              align: "center",
              dataIndex: "eleccomb",
            },
            {
              title: "操作",
              align: "center",
              dataIndex: "action",
              scopedSlots: { customRender: "actions" },
            },
            {
              title: [1, 2, 3, 4, 8].includes(this.subjectId)
                ? "得分"
                : "原始分",
              align: "center",
              sorter: (a, b) => a.scoreOri - b.scoreOri,
              dataIndex: "scoreOri",
            },
            {
              title: "赋分",
              align: "center",
              dataIndex: "score",
              sorter: (a, b) => a.score - b.score,
              customRender: (text, record) => {
                if ([1, 2, 3, 4, 8].includes(this.subjectId)) {
                  return "-";
                } else {
                  return record.score;
                }
              },
            },

            {
              align: "center",
              dataIndex: "classRankOri",
              sorter: (a, b) => a.classRankOri - b.classRankOri,
              slots: { title: "classRankOri" },
            },
            {
              align: "center",
              dataIndex: "schoolRankOri",
              sorter: (a, b) => a.schoolRankOri - b.schoolRankOri,
              slots: { title: "schoolRankOri" },
            },
            {
              align: "center",
              dataIndex: "unionRankOri",
              sorter: (a, b) => a.unionRankOri - b.unionRankOri,
              slots: { title: "unionRankOri" },
            },
            {
              align: "center",
              dataIndex: "classRank",
              slots: { title: "classRank" },
              sorter: (a, b) => a.classRank - b.classRank,
              customRender: (text, record) =>
                [1, 2, 3, 4, 8].includes(this.subjectId)
                  ? "-"
                  : record.classRank,
            },

            {
              align: "center",
              dataIndex: "schoolRank",
              slots: { title: "schoolRank" },
              sorter: (a, b) => a.schoolRank - b.schoolRank,
              customRender: (text, record) =>
                [1, 2, 3, 4, 8].includes(this.subjectId)
                  ? "-"
                  : record.schoolRank,
            },

            {
              title: "薄弱情况",
              align: "center",
              dataIndex: "weak",
              customRender: (text, record) => {
                let weakObj = {
                  0: "-",
                  1: "稍薄弱",
                  2: "严重偏科",
                };
                return weakObj[record.weak];
              },
            },
            {
              title: "答题卡",
              align: "center",
              dataIndex: "answerSheetUrl",
              width: 100,
              customRender: (text, record) => {
                if (record.answerSheetUrl) {
                  return (
                    <a-button type="link" vOn:click={() => this.detail(record)}>
                      查看
                    </a-button>
                  );
                } else {
                  return "无答题卡";
                }
              },
            },
          ];

          if (this.$route.query.examType == 1) {
            if (this.topData.isSchoolDisplayReportStudentRank != 1) {
              cols = this.cols.filter(
                (item) =>
                  !["classRank", "schoolRank", "unionRank"].includes(
                    item.dataIndex
                  )
              );
            } else {
              cols.splice(12, 0, {
                align: "center",
                dataIndex: "unionRank",
                slots: { title: "unionRank" },
                sorter: (a, b) => a.unionRank - b.unionRank,
                defaultSortOrder: "descend",
              });
            }
            if (this.topData.publishSchoolIsAllowTeacherViewAnswerSheet != 1) {
              cols = cols.filter((item) => item.dataIndex !== "answerSheetUrl");
            }
          } else {
            cols = cols.filter(
              (item) => !["unionRank", "unionRankOri"].includes(item.dataIndex)
            );
          }
          if ([1, 2, 3, 4, 8].includes(this.subjectId)) {
            this.columns = cols.filter(
              (item) =>
                !["classRank", "schoolRank", "unionRank", "score"].includes(
                  item.dataIndex
                )
            );
          } else {
            this.columns = [...cols];
          }
        }
        this.tableData = res.data.data.records;
        this.tokenKey = Math.random();
      }
    },
    detail(item) {
      if (item.answerSheetUrl) {
        sessionStorage.setItem("imgList", item.answerSheetUrl);
        const routeUrl = this.$router.resolve({
          path: "/academic/card",
          query: {
            name: item.subjectName,
            statId: this.$route.query.id,
            studentId: item.studentId,
            subjectId: this.subjectId,
          },
        });
        let timer = setTimeout(() => {
          window.open(routeUrl.href, "_blank");
          clearTimeout(timer);
        }, 0);
      } else {
        this.$message.error("无可查看答题卡");
      }
    },
    async getClassNUmList(loadingInstance, io) {
      const res = await getstatclassoption({
        id: this.$route.query.id,
        subjectId: this.subjectId,
        viewSchoolId: this.$route.query.schoolId,
        requestPage: 6,
      }).catch(() => {
        this.closeLoading(loadingInstance, io);
      });
      this.classNUmList = res.data.data;
      if (res.data.data.length) {
        // this.classNum = res.data.data[0].name;
        this.getCate();
        this.getData();
        this.getGourpList();
      }
      this.closeLoading(loadingInstance, io);
    },
    typeChange() {
      this.eleccombId = undefined;
      this.getGourpList();
      this.getData();
    },
    closeLoading(loadingInstance, io) {
      if (loadingInstance && io) {
        this.$nextTick(() => {
          loadingInstance.close();
          io.unobserve(this.$refs.rank);
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.operate {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 18px;
}

.weak {
  span {
    color: #f56c6c;
  }
}

::v-deep .ant-table-tbody > tr > td {
  padding: 0 16px !important;
}

.pagination {
  text-align: right;
  margin-top: 18px;
}

::v-deep .ant-table-column-sorter-inner {
  margin-left: 0 !important;
}
::v-deep .ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  padding: 2px 16px !important;
}
.filter {
  margin-bottom: 0 !important;
}
</style>
