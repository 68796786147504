<template>
  <div class="stat-contain">
    <div class="topData">
      <div class="school-tag">{{ schoolName }}</div>
      <div class="title">
        <div class="new-exam-tag">新高考</div>
        <div class="text">{{ topData.name }}</div>
        <div :class="['sub-tag', 'general']">
          {{ getExamType(topData.type) }}
        </div>
      </div>
      <div class="sub">
        <div class="back" @click="back"><a-icon type="left" /> 返回</div>
        <div class="date">
          <span>年级：{{ topData.year }}级</span>
          <span>考试时间：{{ topData.examDate }}</span>
        </div>
        <div></div>
      </div>
      <div class="model-title">学生榜单</div>
      <div class="a-tabs">
        <a-tabs v-model="subjectId">
          <a-tab-pane
            v-for="item in subjectList"
            :key="item.id"
            :tab="item.name"
          >
          </a-tab-pane>
        </a-tabs>
      </div>
    </div>
    <rankList
      v-if="subjectId !== null"
      :key="subjectId"
      :subject-id="subjectId"
      :top-data="topData"
    />
  </div>
</template>

<script>
import { getStudentRankTopData } from "@/core/api/newExamAcademic/student";
import { getstudentreportstudentlisttopinfo } from "@/core/api/newExamAcademic/union/student";
import { examTypeOptions } from "@/core/util/constdata";
import { getstatsubjectoption } from "@/core/api/academic/common";
import { getStore } from "@/core/util/store";
import rankList from "./rankList";
export default {
  name: "",
  components: {
    rankList,
  },
  data() {
    return {
      topData: {
        type: 99,
      },
      schoolName: null,
      subjectId: null,
      subjectList: [],
    };
  },
  mounted() {
    if (this.$route.query.examType == 1) {
      this.schoolName = this.$route.query.schoolName;
    } else {
      this.schoolName = getStore({ name: "userInfo" }).schoolName;
    }
    this.getTopData();
  },
  methods: {
    getExamType(type) {
      if (type) {
        const data = examTypeOptions.find((item) => item.value === type);
        return data.label;
      }
    },
    async getTopData() {
      let res;
      if (this.$route.query.examType == 1) {
        res = await getstudentreportstudentlisttopinfo({
          id: this.$route.query.id,
          viewSchoolId: this.$route.query.schoolId,
        });
      } else {
        res = await getStudentRankTopData({ id: this.$route.query.id });
      }
      this.topData = res.data.data;
      this.getClassNumList();
    },
    async getClassNumList() {
      const res = await getstatsubjectoption({
        id: this.$route.query.id,
        hasTotal: 1,
        requestPage: 6,
        viewSchoolId: this.$route.query.schoolId,
      });
      this.subjectList = res.data.data;
      if (res.data.data.length) {
        this.subjectId = res.data.data[0].id;
      }
    },
    back() {
      this.$router.go(-1);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../new-exam.module.scss";
.a-tabs {
  width: 100%;
}
::v-deep .ant-tabs-ink-bar {
  display: none !important;
}
::v-deep .ant-tabs-bar {
  border-bottom: none;
}
::v-deep .ant-tabs-tab {
  height: 32px;
  line-height: 32px;
  padding: 0 16px;
}
::v-deep .ant-tabs-tab-active {
  border: 1px solid #2474ed;
  text-shadow: 0 0 0.25px currentColor;
  border-radius: 16px;
  color: #2474ed;
  background: #f3f8ff;
}
::v-deep .ant-tabs-nav-wrap {
  margin-bottom: 0;
}
.subjects {
  margin-bottom: 18px;
  text-align: center;
}
.box {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  .box-item {
    box-sizing: border-box;
    background: #f3f8ff;
    border-radius: 8px;
    padding: 16px 24px 15px 38px;
    display: flex;
    align-items: flex-start;
    .left {
      margin-right: 46px;
      .num {
        color: #2474ed;
        font-size: 18px;
      }
      .title {
        color: #3e4551;
        font-size: 14px;
        margin-top: 12px;
      }
    }
    .right {
      color: #3e4551;
      font-size: 14px;
    }
  }
}
</style>
